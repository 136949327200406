$(document).ready(function () {

  initScroll();
	initSimpleLightbox();

});

function initScroll() {
	$('.scroll-init').on('click',function(e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $($(this).attr('href')).offset().top,
		}, 800);
	});
}

function initSimpleLightbox() {
  $("a.lightbox").simpleLightbox({
    captions: false,
    disableScroll: false
  });
}
